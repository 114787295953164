<script setup lang="ts">
import { onMounted } from 'vue';
import { computed, ref } from 'vue'

const localStorageKey = 'lvm-dark-mode';
const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

const isDark = () => {
  const userPreference = localStorage.getItem(localStorageKey);
  return userPreference == 'dark' || (
    systemPrefersDark && (!userPreference || userPreference == 'system')
  );
};

const isDarkMode = ref(isDark());
const iconClass = computed(() => "pi " + (isDarkMode.value ? "pi-moon" : "pi-sun"));

const setTheme = () => {
  if (isDarkMode.value) {
    document.documentElement.classList.add('dark');
    document.documentElement.classList.add('cc--darkmode');
  } else {
    document.documentElement.classList.remove('dark');
    document.documentElement.classList.remove('cc--darkmode');
  }
};

const toggleDarkMode = () => {
  isDarkMode.value = !isDarkMode.value;
  setTheme();
  localStorage.setItem(localStorageKey, isDarkMode.value ? "dark" : "light");
};

onMounted(() => {
  setTheme();
});
</script>

<template>
  <Button
    :icon="iconClass"
    size="small"
    severity="secondary"
    aria-label="Toggle dark mode"
    @click="toggleDarkMode"
  />
</template>
