import type { App } from "vue";
import posthog from "posthog-js";

export default {
  install(app: App, apiKey: string) {
    app.config.globalProperties.$posthog = posthog.init(apiKey, {
      api_host: "https://us.i.posthog.com",
    });
  },
};
