<script setup lang="ts">
import { computed, ref } from "vue";
import { useAuth0 } from '@auth0/auth0-vue';
import { useRouter } from "vue-router";
import DarkModeToggler from "@/components/common/DarkModeToggler.vue";
// import UserDropdown from "@/components/UserDropdown.vue";
import TopBar from '@/components/common/TopBar.vue';
import Button from "primevue/button";

const { logout  } = useAuth0();
const router = useRouter();

const showMenu = ref(false);

const currentRoute = computed(() => router.currentRoute.value.name);
const isHelp = computed(() => currentRoute.value === "help");
const isAccount = computed(() => currentRoute.value === "account");
const isVideos = computed(() => ["create-video", "video", "videos"].includes(currentRoute.value as string));

const onLogout = () => {
  logout({ logoutParams: { returnTo: window.location.origin } });
};
</script>

<template>
  <TopBar :to="{ name: 'dashboard' }" border>
    <div class="sm:hidden flex items-center gap-2">
      <DarkModeToggler
        size="medium"
        rounded
        text
      />
      <Button
        :icon="`pi ${showMenu ? 'pi-times' : 'pi-bars'}`"
        size="medium"
        severity="secondary"
        aria-label="Menu"
        rounded
        text
        @click="showMenu = !showMenu"
      />
      <div class="absolute inset-x-0 top-[73px] py-5 bg-surface-0 dark:bg-zinc-950 z-50 border-b border-zinc-100 dark:border-zinc-800"
        :class="{ 'hidden': !showMenu }"
        @click="showMenu = false"
      >
        <div class="mx-auto max-w-40 flex flex-col sm:flex-row items-center gap-2">
          <Button
            as="router-link"
            :to="{ name: 'videos' }"
            label="Videos"
            :severity="isVideos ? 'primary' : 'secondary'"
            rounded
            :text="!isVideos"
            class="w-full"
            :class="{ '!h-9': isVideos }"
          />
          <Button
            as="router-link"
            :to="{ name: 'help' }"
            label="Help"
            :severity="isHelp ? 'primary' : 'secondary'"
            rounded
            :text="!isHelp"
            class="w-full"
            :class="{ '!h-9': isHelp }"
          />
          <Button
            as="router-link"
            :to="{ name: 'account' }"
            label="Your Account"
            :severity="isAccount ? 'primary' : 'secondary'"
            aria-label="Your Account"
            rounded
            :text="!isAccount"
            class="w-full"
            :class="{ '!h-9': isAccount }"
          />
          <Button
            label="Logout"
            severity="secondary"
            aria-label="Logout"
            rounded
            text
            class="w-full"
            @click="onLogout"
          />
        </div>
      </div>
    </div>

    <div class="hidden sm:flex items-center gap-1">
      <Button
        as="router-link"
        :to="{ name: 'videos' }"
        label="Videos"
        :severity="isVideos ? 'primary' : 'secondary'"
        size="small"
        rounded
        :text="!isVideos"
        :class="{ '!h-8': isVideos }"
      />
      <Button
        as="router-link"
        :to="{ name: 'help' }"
        label="Help"
        :severity="isHelp ? 'primary' : 'secondary'"
        size="small"
        rounded
        :text="!isHelp"
        :class="{ '!h-8': isHelp }"
      />
      <DarkModeToggler
        v-tooltip.bottom="{ value: 'Switch Theme', pt: { text: '!text-sm' } }"
        rounded
        text
      />
      <Button
        v-tooltip.bottom="{ value: 'Your Account', pt: { text: '!text-sm' } }"
        as="router-link"
        :to="{ name: 'account' }"
        icon="pi pi-user"
        size="small"
        :severity="isAccount ? 'primary' : 'secondary'"
        aria-label="Your Account"
        rounded
        :text="!isAccount"
        :class="{ '!size-8 mx-1': isAccount }"
      />
      <Button
        v-tooltip.bottom="{ value: 'Logout', pt: { text: '!text-sm' } }"
        icon="pi pi-power-off"
        size="small"
        severity="secondary"
        aria-label="Logout"
        rounded
        text
        @click="onLogout"
      />
      <!-- <UserDropdown /> -->
    </div>
  </TopBar>
</template>
